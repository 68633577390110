import React from 'react';
import NotFoundSection from '../sections/NotFoundSection/NotFoundSection';

const NotFound = () => {
  return (
    <>
      <NotFoundSection />
    </>
  );
};

export default NotFound;
