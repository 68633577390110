import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled.form`
  width: 350px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 20px rgba(51, 51, 51, 0.14);
  margin: 40px 0;

  h3 {
    margin-top: 0;
  }
`;
