const theme = {
  //primary: '#5FC6F5',
  primary: '#8D83FE',
  secondary: '#18516C',
  accent: '#5FC6F5',
  grey: '#FBFBFB',
  greyBcg: '#f3f3f3',
  shadow: 'rgba(141, 131, 239, .32)',
  font: {
    size: {
      xxl: '6rem',
      xl: '3.6rem',
      l: '3rem',
      m: '2.4rem',
      r: '1.6rem',
      s: '1.4rem',
      xs: '1.2rem',
    },
    weight: {
      black: 900,
      bold: 700,
      semiBold: 600,
      medium: 500,
      regular: 400,
    },
  },
};

export default theme;
