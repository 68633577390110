import React from 'react';
import styled, { keyframes } from 'styled-components';

const flashAnimation = keyframes`
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: .6;
  }
  100% {
    opacity: 1;
  }
`;

const StyledSvg = styled.svg`
  max-width: 100%;

  .flash {
    fill: #f5fcfd;
    animation: ${flashAnimation} 1.5s infinite linear alternate;
  }
`;

const HouseImage = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 1078.164 405.35"
    >
      <defs>
        <linearGradient
          id="a"
          x1="0.547"
          x2="0.5"
          y1="0.969"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#9189fe"></stop>
          <stop offset="1" stopColor="#7bb3fe"></stop>
        </linearGradient>
        <linearGradient
          id="b"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#7bb3fe"></stop>
          <stop offset="1" stopColor="#8c84fe"></stop>
        </linearGradient>
        <linearGradient
          id="c"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#7ab2fd"></stop>
          <stop offset="1" stopColor="#888dfe"></stop>
        </linearGradient>
      </defs>
      <g style={{ isolation: 'isolate' }}>
        <path
          fill="url(#a)"
          d="M373.268-642.265c-1.564 0-3.145-.061-4.755-.193-13.7-1.125-27.673-6.867-35.561-18.124-9.395-13.407-8.816-32.24-19.275-44.836-14.812-17.843-48.36-19.529-52.425-42.359-2.121-11.909 6.075-23.363 15.721-30.661s21.1-12.1 30.124-20.153c17.315-15.447 23.64-40.905 42.1-54.963 10.5-8 22.833-11 35.813-11 16.045 0 33.083 4.583 48.879 9.975l-35.455 62.352h117.547a46.822 46.822 0 011.425 14.373l-60.586-6.7v133.536q-.594.006-1.189.005c-3.788 0-7.583-.128-11.413-.256s-7.692-.255-11.613-.255c-3.115 0-6.268.08-9.469.305-17.283 1.215-32.268 8.952-49.867 8.952"
          transform="translate(-260.915 1002.672)"
        ></path>
        <path
          fill="#18516c"
          d="M182.607 283.742l-4.593-3.873-30.467 36.103v-135.83h-6.008v82.205l-27.219-37.589-4.864 3.52 32.083 44.307V405.35h6.008v-80.06l35.06-41.548"
        ></path>
        <path
          fill="url(#b)"
          d="M1277.874-650.485c-9.28 0-18.621-1.22-27.913-2.379-14.62-1.824-30.279-.129-44.6-4.689-13.392-4.264-25.717-14.711-28.167-28.552-1.812-10.241 1.821-21.283-2.037-30.941-2.467-6.179-7.533-10.649-12.5-15.22l87.191-153.334a38.842 38.842 0 017.66-1.279 33.927 33.927 0 012.139-.068c16.108 0 32.234 11.551 34.9 27.443 1.412 8.424-.767 17.119.471 25.569 2.265 15.48 15.119 26.812 26.059 37.993s21.325 26.3 17.005 41.34c-3.053 10.628-13.055 18.851-13.7 29.889-.536 9.157 5.58 17.293 7.6 26.241 4.275 18.9-11.216 37.725-29.49 44.164a73.145 73.145 0 01-24.611 3.825"
          transform="translate(-260.915 1002.672)"
        ></path>
        <path
          fill="#18516c"
          d="M1019.185 234.721l-4.195-4.307-26.456 25.793v-54.414h-6.008v112.43l-32.116-45.52-4.911 3.463 37.027 52.48v80.7h6.008V264.605l30.651-29.884"
        ></path>
        <path
          fill="#00d4bb"
          d="M751.885 381.16c-4.9-8.813-14.236-14.159-23.467-18.224a168.556 168.556 0 00-34.795-10.848c-32.564 7.844-55.784 26.781-55.784 39.783v6.4a7.076 7.076 0 007.076 7.076H754.72c1.643-8.179 1.2-16.937-2.836-24.189"
        ></path>
        <path
          fill="#00b7b2"
          d="M723.732 348.439a128.509 128.509 0 00-30.107 3.649 168.554 168.554 0 0134.795 10.848c9.231 4.065 18.563 9.411 23.467 18.224 4.033 7.252 4.479 16.011 2.836 24.189h47.825a7.076 7.076 0 007.075-7.076v-6.4c0-16.747-38.455-43.432-85.89-43.432"
        ></path>
        <path
          fill="#0088db"
          d="M1000.739 74.642h-414.68L465.803 286.128h414.682l120.254-211.486"
        ></path>
        <path
          fill="#0149bf"
          d="M1000.739 74.642L880.485 286.128H465.803l5.8 11.461h414.682l120.257-211.487-5.8-11.46"
        ></path>
        <path
          fill="#c8e8e2"
          d="M667.864 174.378l21.341-37.533 21.341-37.533H603.308l-42.683 75.066h107.239"
        ></path>
        <path
          fill="#b1e4d9"
          d="M553.794 186.394l-42.686 75.068h107.237l21.34-37.535 21.341-37.533H553.794"
        ></path>
        <path
          fill="#c8e8e2"
          d="M724.367 99.312l-42.682 75.066h113.772l21.34-37.533 21.341-37.533H724.366m127.592 0l-42.683 75.066h109.277l42.681-75.066H851.958"
        ></path>
        <path
          fill="#e4f6f8"
          d="M781.101 223.927l21.343-37.533h109.277l-42.683 75.068h-109.28l21.343-37.535m28.175-49.549l42.682-75.066h109.276l-42.681 75.066H809.276m7.52-37.533l-21.34 37.533H681.685l42.683-75.066h113.77l-21.341 37.533M710.546 99.312l-21.341 37.533-21.341 37.533H560.626l42.682-75.066h107.238m-92.2 162.15H511.108l42.685-75.068h107.238l-21.346 37.533-21.34 37.535m127.592 0h-113.77l42.685-75.068h113.77l-42.685 75.068M981.885 87.296H596.32l-52.435 92.21-53.43 93.973h385.571l56.348-99.1 49.515-87.082"
        ></path>
        <path
          fill="#c8e8e2"
          d="M911.721 186.394H802.444l-21.343 37.533-21.343 37.535h109.28l42.683-75.068m-236.869 0l-42.685 75.068h113.77l42.685-75.068h-113.77"
        ></path>
        <path
          fill="#e2f5ef"
          className="flash"
          d="M643.675 132.634l-14.809-24.979 13.987-8.344h56.678l-55.856 33.323"
        ></path>
        <path
          fill="#f5fcfd"
          className="flash"
          d="M699.531 99.312h-56.679l20.143-12.016h56.677l-20.141 12.016"
        ></path>
        <path
          fill="#84d7f7"
          d="M932.374 174.378l5.415-9.524-5.415 9.524"
        ></path>
        <path
          fill="#f5fcfd"
          className="flash"
          d="M876.026 273.479H755.713l20.141-12.017h93.184l42.683-75.068h-10.036l36.106-21.54-5.415 9.524-23.681 41.648-32.667 57.453"
        ></path>
        <path
          fill="#e2f5ef"
          className="flash"
          d="M869.038 261.462h-93.183l125.83-75.068h10.039l-42.683 75.068M654.797 151.395l-4.907-8.277 54.1-32.273-9.641 16.956-39.549 23.595m60.463-36.072l9.1-16.012h17.735l-26.834 16.01"
        ></path>
        <path
          fill="#f5fcfd"
          className="flash"
          d="M694.347 127.801l9.641-16.956 39.475-23.549h18.78l-20.141 12.016h-17.735l-9.1 16.012-20.915 12.477"
        ></path>
        <path
          fill="#e2f5ef"
          className="flash"
          d="M691.552 174.378h-9.867l1.056-1.857 122.713-73.209h11.931l-125.83 75.066"
        ></path>
        <path
          fill="#f5fcfd"
          className="flash"
          d="M674.467 184.572l-3.116-5.256 11.389-6.795-1.056 1.857h9.868l-17.085 10.194m142.918-85.26h-11.928l20.142-12.016h11.927l-20.141 12.016"
        ></path>
        <path
          fill="#e2f5ef"
          className="flash"
          d="M778.876 174.378h-18.78l53.511-31.924-9.639 16.955-25.091 14.969m46.008-27.447l9.641-16.956 51.4-30.664h18.78l-79.821 47.62"
        ></path>
        <path
          fill="#f5fcfd"
          className="flash"
          d="M758.733 186.394h-18.78l20.143-12.016h18.78l-20.143 12.016m45.235-26.985l9.639-16.955 20.916-12.477-9.64 16.955-20.915 12.477m100.736-60.1h-18.78l20.141-12.016h18.78l-20.141 12.016"
        ></path>
        <path
          fill="#e2f5ef"
          className="flash"
          d="M651.46 250.391v-11.2l88.493-52.795h18.78l-107.273 64"
        ></path>
        <path
          fill="#00b7b2"
          d="M752.396 297.59h-57.328v6.528c19.379 6.612 38.484 14.005 57.328 22.015V297.59"
        ></path>
        <path
          fill="#66e4d2"
          d="M695.068 304.118v54.839c0 7.729 12.832 14 28.664 14s28.664-6.267 28.664-14v-32.825c-18.844-8.009-37.949-15.4-57.328-22.015"
        ></path>
        <path
          fill="#00b7b2"
          d="M195.905 210.449v7.671l242.589 26.84v-34.511H195.905"
        ></path>
        <path
          fill="#66e4d2"
          d="M195.905 405.349h242.589v-160.39l-242.589-26.84v187.23"
        ></path>
        <path fill="#00d4bb" d="M556.676 0h-76.714v34.211h76.714V0"></path>
        <path
          fill="#00b7b2"
          d="M556.676 34.21h-27.991V-.001h27.989V34.21"
        ></path>
        <path
          fill="#0149bf"
          d="M238.102 33.562H584.94l104.868 176.887H342.97L238.102 33.562"
        ></path>
        <path
          fill="#0088db"
          d="M651.46 210.449L567.908 63.514l-83.55 146.934h-45.864v34.511L651.46 363.5V210.449"
        ></path>
        <path
          fill="#77c9c3"
          d="M438.494 244.959v160.39H651.46V363.5L438.494 244.959"
        ></path>
        <path
          fill="#0149bf"
          d="M635.585 276.359h-77.99v128.99h77.99v-128.99m-166.1 62.396h74.43v-82.33h-74.43z"
        ></path>
        <path
          fill="#71bef2"
          d="M584.94 33.562H238.102L137.52 210.449h346.838L584.94 33.562"
        ></path>
        <path fill="#c8e8e2" d="M220.68 325.627h196.142v-70.314H220.68z"></path>
        <path
          fill="#e4f6f8"
          d="M416.822 325.627H220.68v-70.315h196.142v70.315m-202.15-76.323v82.33h208.16v-82.33h-208.16"
        ></path>
        <path
          fill="#0049be"
          d="M606.344 187.857a33.735 33.735 0 00-33.693-33.7 33.735 33.735 0 00-33.695 33.7 33.733 33.733 0 0033.695 33.691 33.732 33.732 0 0033.693-33.691"
        ></path>
        <path
          fill="#0049be"
          d="M538.956 187.857a33.735 33.735 0 0133.695-33.7 33.735 33.735 0 0133.693 33.7 33.732 33.732 0 01-33.693 33.691 33.733 33.733 0 01-33.695-33.691m33.695-38.96a39 39 0 00-38.957 38.96 39 39 0 0038.957 38.953 39 39 0 0038.956-38.953 39 39 0 00-38.956-38.96"
        ></path>
        <path
          fill="#59c1b2"
          d="M469.485 325.627h62.413v-70.314h-62.413z"
        ></path>
        <path
          fill="#87c8c2"
          d="M531.902 325.627h-62.417v-70.315h62.413v70.315m-68.422-76.323v82.33h74.43v-82.33h-74.43"
        ></path>
        <path
          fill="#7ab5af"
          d="M599.172 180.879a33.735 33.735 0 00-33.695-33.7 33.735 33.735 0 00-33.695 33.7 33.734 33.734 0 0033.696 33.693 33.734 33.734 0 0033.695-33.692"
        ></path>
        <path
          fill="#a1c3c4"
          d="M531.785 180.879a33.735 33.735 0 0133.695-33.7 33.735 33.735 0 0133.695 33.7 33.734 33.734 0 01-33.697 33.693 33.734 33.734 0 01-33.693-33.693m33.695-38.96a39 39 0 00-38.957 38.96 39 39 0 0038.957 38.955 39 39 0 0038.959-38.955 39 39 0 00-38.959-38.96"
        ></path>
        <path
          fill="#15b1ae"
          d="M532.231 55.452l-14.868 5.633h-244.3l-35.878 63.107-31.2 54.859-10.08 3.821 4.709 5.632h263.871l38.564-67.821 33.885-59.6-4.708-5.633"
        ></path>
        <path
          fill="#b1e4d9"
          d="M317.32 115.05l14.605-25.688 14.605-25.69h-73.39l-29.211 51.375h73.39m-78.066 8.225l-29.213 51.373h73.392l14.605-25.687 14.607-25.686h-73.39m116.731-59.6l-29.211 51.375h77.862l14.605-25.688 14.61-25.687h-77.866m87.325 0l-29.211 51.375h74.786l29.211-51.375H443.31"
        ></path>
        <path
          fill="#e4f6f8"
          d="M394.817 148.961l14.606-25.689h74.786l-29.211 51.373h-74.787l14.605-25.687m19.283-33.911l29.211-51.375h74.786l-29.211 51.378h-74.786m5.147-25.688l-14.605 25.688h-77.863l29.207-51.378h77.862l-14.601 25.69m-72.716-25.69l-14.605 25.69-14.605 25.688h-73.391l29.211-51.378h73.391m-63.1 110.972H210.04l29.213-51.372h73.39l-14.607 25.687-14.605 25.687m87.321 0h-77.867l29.217-51.374h77.863l-29.212 51.373m161.478-119.2H268.357l-35.883 63.108-36.569 64.319h263.88l38.563-67.821 33.887-59.6"
        ></path>
        <path
          fill="#b1e4d9"
          d="M484.21 123.272h-74.787l-14.607 25.687-14.605 25.687h74.788l29.211-51.374m-162.108 0l-29.217 51.375h77.864l29.216-51.375h-77.863"
        ></path>
        <path
          fill="#b6e7fa"
          d="M225.021 131.672l7.453-13.109-7.453 13.109"
        ></path>
        <path
          fill="#e2f5ef"
          d="M252.878 115.05h-8.949l15.317-26.941 40.956-24.437h38.789l-86.113 51.375"
        ></path>
        <path
          fill="#f5fcfd"
          d="M225.021 131.672l7.453-13.109 12.459-21.911 14.315-8.54-15.319 26.941h8.949l-27.857 16.619m113.971-68h-38.79l13.783-8.22h38.789l-13.782 8.22"
        ></path>
        <path fill="#81d5d4" d="M498.344 115.05l3.705-6.519-3.705 6.519"></path>
        <path
          fill="#f5fcfd"
          d="M459.785 182.872h-82.34l13.784-8.224h63.773l29.208-51.376h-6.869l24.709-14.743-3.705 6.519-16.207 28.5-22.353 39.324"
        ></path>
        <path
          fill="#e2f5ef"
          d="M454.999 174.647h-63.773l86.115-51.375h6.869l-29.211 51.373"
        ></path>
        <path fill="#b6e7fa" d="M210.063 157.972l6.6-11.6-6.6 11.6"></path>
        <path
          fill="#e2f5ef"
          d="M224.378 149.433l6.6-11.6 24.4-14.556h12.852l-43.851 26.159m57.633-34.386H269.16l72.882-43.478-6.6 11.6-53.433 31.877m67.749-40.415l6.227-10.962h12.137l-18.367 10.957"
        ></path>
        <path
          fill="#f5fcfd"
          d="M210.064 157.972l6.6-11.6 14.313-8.539-6.6 11.6-14.313 8.54m58.164-34.7h-12.852l13.784-8.224h12.852l-13.784 8.223m67.217-40.1l6.6-11.6 27.016-16.117h12.853l-13.788 8.217h-12.141l-6.231 10.959-14.309 8.541"
        ></path>
        <path
          fill="#e2f5ef"
          d="M233.633 174.647h-8.163l86.115-51.375h1.059l-3.647 6.412-75.362 44.96m99.898-59.594h-6.755l.723-1.272 83.984-50.106h8.163l-86.113 51.375"
        ></path>
        <path
          fill="#f5fcfd"
          d="M219.849 182.872h-8.164l13.784-8.224h8.163l-13.783 8.224m89.148-53.185l3.647-6.412h-1.059l15.916-9.5-.723 1.272h6.755l-24.537 14.637m110.65-66.012h-8.161l13.785-8.224h8.163l-13.787 8.224"
        ></path>
        <path
          fill="#e2f5ef"
          d="M283.432 174.647h-2.889l4.372-2.608-1.483 2.608"
        ></path>
        <path
          fill="#f5fcfd"
          d="M279.61 182.872h-12.853l13.785-8.224h2.889l1.483-2.608 14.315-8.54-6.34 11.148h.505l-13.784 8.224"
        ></path>
        <path
          fill="#e2f5ef"
          d="M393.294 115.05h-12.853l36.623-21.848-6.6 11.6-17.171 10.245m31.486-18.781l6.6-11.6 35.176-20.994h12.852l-54.628 32.591"
        ></path>
        <path
          fill="#f5fcfd"
          d="M379.509 123.272h-12.852l13.784-8.224h12.853l-13.785 8.224m30.956-18.469l6.6-11.6 14.315-8.54-6.6 11.6-14.313 8.539m68.94-41.13h-12.852l13.784-8.224h12.853l-13.785 8.224"
        ></path>
        <path
          fill="#e2f5ef"
          d="M293.394 174.647h-.509l6.34-11.148 67.432-40.227h12.852l-86.114 51.373M220.68 287.488v-18.166l23.537-14.009h30.521l-54.059 32.176"
        ></path>
        <path
          fill="#f5fcfd"
          d="M214.672 291.064v-18.167l6.008-3.576v18.167l-6.008 3.576m60.066-35.752h-30.521l10.095-6.008h30.521l-10.095 6.008"
        ></path>
        <path
          fill="#e2f5ef"
          d="M220.68 304.308V297.1l70.205-41.788h12.111l-82.318 49"
        ></path>
        <path
          fill="#f5fcfd"
          d="M214.672 307.884v-7.212l6.008-3.576v7.208l-6.008 3.576m88.326-52.572h-12.113l10.095-6.008h12.109l-10.093 6.008"
        ></path>
        <path
          fill="#e2f5ef"
          d="M234.324 325.627h-6.72l118.134-70.315h6.723l-118.137 70.315"
        ></path>
        <path
          fill="#f5fcfd"
          d="M224.23 331.635h-6.723l10.1-6.008h6.72l-10.093 6.008m128.23-76.322h-6.723l10.095-6.008h6.721l-10.093 6.008"
        ></path>
        <path
          fill="#ebf7f4"
          d="M377.593 325.627h-54.985l94.214-56.076v32.727l-39.229 23.349"
        ></path>
        <path
          fill="#f5fcfd"
          d="M367.498 331.635h-54.984l10.093-6.008h54.985l-10.095 6.008m49.324-29.357v-32.727l6.009-3.576v32.727l-6.009 3.576"
        ></path>
        <path
          fill="#b0d3cf"
          d="M532.03 184.972a33.9 33.9 0 01-.247-4.088 33.489 33.489 0 012.741-13.309l.016-.011 33.976-20.223.027-.016a33.5 33.5 0 0116.464 6.115l-.027.016-52.924 31.5-.027.015"
        ></path>
        <path
          fill="#b3ded9"
          d="M527.185 187.854a38.918 38.918 0 01-.66-6.975v-.149a38.856 38.856 0 011.151-9.081l6.853-4.077a33.489 33.489 0 00-2.741 13.309 33.912 33.912 0 00.247 4.088l-4.849 2.887m57.827-34.419a33.5 33.5 0 00-16.464-6.115l6.845-4.075a38.839 38.839 0 0114.479 7.3l-4.86 2.892"
        ></path>
        <path
          fill="#b0d3cf"
          d="M536.064 197.301a33.411 33.411 0 01-2.273-4.961l.036-.021 56.788-33.8.032-.019a34.07 34.07 0 013.289 4.356l-.029.016-57.828 34.42-.015.009"
        ></path>
        <path
          fill="#b3ded9"
          d="M531.562 199.979a38.529 38.529 0 01-2.332-4.925l4.561-2.715a33.424 33.424 0 002.273 4.961l-4.5 2.679m62.375-37.124a34.06 34.06 0 00-3.289-4.356l4.576-2.724a39.366 39.366 0 013.2 4.408l-4.489 2.672"
        ></path>
        <path
          fill="#94dacd"
          d="M469.485 303.596v-7.208l62.413-37.148v7.208l-62.413 37.148"
        ></path>
        <path
          fill="#b3ded9"
          d="M463.479 307.172v-7.208l6.009-3.577v7.208l-6.009 3.577m68.422-40.725v-7.208l6.008-3.576v7.207l-6.008 3.577"
        ></path>
        <path
          fill="#94dacd"
          d="M481.938 325.627h-6.721l56.685-33.74v4l-49.964 29.739"
        ></path>
        <path
          fill="#b3ded9"
          d="M471.843 331.635h-6.721l10.095-6.008h6.721l-10.095 6.008m60.059-35.747v-4l6.008-3.575v4l-6.008 3.575"
        ></path>
        <path
          fill="#0149bf"
          d="M598.802 316.744c5.637 10.808 11.027 21.74 15.985 32.881a396.845 396.845 0 0116.533 43.621V325.43q-16.178-4.64-32.519-8.687"
        ></path>
        <path
          fill="#0149bf"
          d="M631.32 325.431V281.98h-51.552c6.532 11.491 12.929 23.055 19.033 34.764q16.336 4.054 32.519 8.687"
        ></path>
        <path
          fill="#0149bf"
          d="M579.768 281.98h-17.906v26.4q18.55 3.818 36.94 8.368c-6.1-11.709-12.5-23.273-19.033-34.764"
        ></path>
        <path
          fill="#0088db"
          d="M598.802 316.744q-18.38-4.564-36.94-8.368v96.973h69.458v-12.1a396.85 396.85 0 00-16.535-43.623c-4.959-11.141-10.348-22.073-15.985-32.881"
        ></path>
        <path
          fill="url(#c)"
          d="M1222.269-597.323h-187.833c-6.308-5.137-12.519-12.085-15.712-19.567s-3.155-16.764 1.828-23.193c3.661-4.725 9.388-7.328 13.827-11.331 6.253-5.637 9.623-13.7 14.228-20.747s11.656-13.675 20.071-13.415c6.663.208 12.536 4.715 19.171 5.361 9.853.961 18.224-6.523 27.187-10.729 15.119-7.1 32.952-4.873 49.016-.3 25.157 7.163 49.881 20.832 62.982 43.473 9.121 15.763 6.045 35.788-4.764 50.448"
          transform="translate(-260.915 1002.672)"
        ></path>
      </g>
    </StyledSvg>
  );
};

export default HouseImage;
