import React from 'react';
import { StyledWrapper } from './styles';

const PanelsImage = ({ ...props }) => {
  return (
    <StyledWrapper {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1174.955 483.275">
        <defs>
          <linearGradient id="a" x1="-5.017" x2="-5.016" y1="2.072" y2="2.072" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7bb3fe"></stop>
            <stop offset="1" stopColor="#9178ff"></stop>
          </linearGradient>
          <linearGradient id="b" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7bb3fe"></stop>
            <stop offset="1" stopColor="#8e7ffe"></stop>
          </linearGradient>
          <linearGradient id="c" x1="-0.712" x2="-0.711" y1="2.608" y2="2.608" xlinkHref="#a"></linearGradient>
          <linearGradient id="d" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7bb3fe"></stop>
            <stop offset="1" stopColor="#8c84fe"></stop>
          </linearGradient>
          <linearGradient id="e" x1="-4.465" x2="-4.464" y1="4.184" y2="4.184" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#7c8a9e"></stop>
            <stop offset="1" stopColor="#9178ff"></stop>
          </linearGradient>
          <linearGradient id="f" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#74b0fc"></stop>
            <stop offset="1" stopColor="#8c8ffe"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#a)"
          d="M1072.673-435.452c-12.147 0-22.4-5.912-33.832-7.911v-21.067h66.434c1.044 14.663-13.419 26.856-28.068 28.7a36.4 36.4 0 01-4.535.281m-76.97-4.085a45.368 45.368 0 01-5.789-.359c-14.107-1.808-28.305-9.065-34.748-21.743q-.7-1.382-1.267-2.791h77.444v20.411c-5.967 0-11.8 1.123-17.683 2.241s-11.821 2.24-17.957 2.24m-46.14-100.825c-5.055-12.2-12.4-24.217-7.528-35.936 5.125-12.329 21.823-17.184 25.807-29.928 4.131-13.211-7.5-25.476-13.341-38.023-8.036-17.26-5.048-37.111-.617-55.928l21.235-37.344h-10.936a114.461 114.461 0 0113.952-28.389c10.089-14.407 24.384-25.421 34.811-39.587 5.855-7.955 10.459-16.859 17.112-24.159 6.065-6.653 14.481-11.983 23.349-11.983a21.839 21.839 0 012.6.155c12.823 1.539 21.611 14.184 24.707 26.723s2.241 25.759 4.915 38.395c7.571 35.781 42.391 63.414 42.265 99.988-.028 8.064-1.815 15.944-3.892 23.788h-85.156v-73.569h-7.5v73.569h-17.967L949.562-540.36"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="url(#b)"
          d="M1072.673-435.452c-12.147 0-22.4-5.912-33.832-7.911v-21.067h66.434c1.044 14.663-13.419 26.856-28.068 28.7a36.4 36.4 0 01-4.535.281m-76.97-4.085a45.368 45.368 0 01-5.789-.359c-14.107-1.808-28.305-9.065-34.748-21.743q-.7-1.382-1.267-2.791h77.444v20.411c-5.967 0-11.8 1.123-17.683 2.241s-11.821 2.24-17.957 2.24m-46.14-100.825c-5.055-12.2-12.4-24.217-7.528-35.936 5.125-12.329 21.823-17.184 25.807-29.928 4.131-13.211-7.5-25.476-13.341-38.023-8.036-17.26-5.048-37.111-.617-55.928l21.235-37.344h-10.936a114.461 114.461 0 0113.952-28.389c10.089-14.407 24.384-25.421 34.811-39.587 5.855-7.955 10.459-16.859 17.112-24.159 6.065-6.653 14.481-11.983 23.349-11.983a21.839 21.839 0 012.6.155c12.823 1.539 21.611 14.184 24.707 26.723s2.241 25.759 4.915 38.395c7.571 35.781 42.391 63.414 42.265 99.988-.028 8.064-1.815 15.944-3.892 23.788h-85.156v-73.569h-7.5v73.569h-17.967L949.562-540.36"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#1e5675"
          d="M1078.1-639.9l-6.26-4.129-33 50.113v-132.241h-7.5v231.519l-42.305-43.587-5.383 5.229 47.688 49.132v125.5h7.5v-221.912l39.26-59.624"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="url(#c)"
          d="M341.334-409.887c-1.792 0-3.6-.071-5.449-.221-15.7-1.289-31.727-7.873-40.771-20.779-10.772-15.371-10.108-36.965-22.1-51.408-16.984-20.453-55.445-22.388-60.108-48.561-2.432-13.655 6.965-26.785 18.025-35.153s24.188-13.876 34.536-23.108c19.852-17.708 27.1-46.9 48.268-63.013 12.04-9.168 26.179-12.608 41.06-12.608 21.336 0 44.2 7.068 64.6 14.453 8.415 3.047 16.913 6.141 25.187 9.661L361.81-495.048h146.581c10.252 26.517-10.079 57.663-36.588 68.837-12.333 5.2-24.151 6.295-36.039 6.295-4.343 0-8.693-.145-13.084-.292s-8.816-.292-13.309-.292c-3.575 0-7.191.092-10.864.351-19.813 1.392-37 10.263-57.173 10.263"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="url(#d)"
          d="M341.334-409.887c-1.792 0-3.6-.071-5.449-.221-15.7-1.289-31.727-7.873-40.771-20.779-10.772-15.371-10.108-36.965-22.1-51.408-16.984-20.453-55.445-22.388-60.108-48.561-2.432-13.655 6.965-26.785 18.025-35.153s24.188-13.876 34.536-23.108c19.852-17.708 27.1-46.9 48.268-63.013 12.04-9.168 26.179-12.608 41.06-12.608 21.336 0 44.2 7.068 64.6 14.453 8.415 3.047 16.913 6.141 25.187 9.661L361.81-495.048h146.581c10.252 26.517-10.079 57.663-36.588 68.837-12.333 5.2-24.151 6.295-36.039 6.295-4.343 0-8.693-.145-13.084-.292s-8.816-.292-13.309-.292c-3.575 0-7.191.092-10.864.351-19.813 1.392-37 10.263-57.173 10.263"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#215872"
          d="M421.882-497.784l-5.267-4.44-34.929 41.393v-155.73H374.8v94.248l-31.207-43.1-5.576 4.037 36.783 50.8v152.216h6.889v-91.788l40.2-47.636"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#00d4bb"
          d="M689.8-386.094c-5.621-10.1-16.321-16.233-26.9-20.893a193.148 193.148 0 00-39.892-12.437c-37.333 8.993-63.956 30.705-63.956 45.612v7.34a8.112 8.112 0 008.112 8.112h125.893c1.883-9.377 1.372-19.42-3.253-27.733"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#00b7b2"
          d="M657.526-423.608a147.382 147.382 0 00-34.519 4.184 193.15 193.15 0 0139.893 12.437c10.583 4.66 21.283 10.789 26.9 20.893 4.625 8.313 5.136 18.356 3.253 27.733h54.831a8.112 8.112 0 008.116-8.111v-7.34c0-19.2-44.086-49.8-98.473-49.8"
          transform="translate(-212.522 841.635)"
        ></path>
        <path fill="#0088db" d="M975.116-737.518H499.684L361.81-495.048h475.432l137.874-242.47" transform="translate(-212.522 841.635)"></path>
        <path
          fill="#0049bf"
          d="M975.116-737.518l-137.874 242.47H361.81l6.652 13.14h475.431l137.874-242.47-6.651-13.14"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#c8e8e2"
          d="M593.472-623.169l24.469-43.031 24.467-43.033H519.46l-48.936 86.065h122.948M462.691-609.392l-48.939 86.064H536.7l24.469-43.033 24.469-43.031H462.691M658.253-709.234l-48.935 86.065h130.439l24.466-43.031 24.469-43.033H658.253M804.537-709.234L755.6-623.169h125.289l48.935-86.065H804.537"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#eff"
          d="M723.3-566.361l24.471-43.031h125.283l-48.935 86.064H698.83l24.47-43.033m32.3-56.808l48.935-86.065h125.288l-48.935 86.065H755.6m8.621-43.032l-24.467 43.032H609.318l48.935-86.065h130.44l-24.47 43.034m-121.815-43.034L617.941-666.2l-24.469 43.032H470.524l48.936-86.065h122.948M536.7-523.328H413.752l48.939-86.064h122.948l-24.469 43.031-24.47 43.033m146.285 0H552.547l48.937-86.064h130.44l-48.938 86.064M953.5-723.01H511.448l-60.112 105.72-61.263 107.74H832.13l64.6-113.618 56.77-99.842"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#c8e8e2"
          d="M873.054-609.392H747.769L723.3-566.361l-24.47 43.033h125.289l48.935-86.064M601.484-609.392l-48.937 86.064h130.438l48.939-86.064h-130.44"
          transform="translate(-212.522 841.635)"
        ></path>
        <path fill="#a1d3f2" d="M438.848-595.328l12.488-21.963-12.488 21.963" transform="translate(-212.522 841.635)"></path>
        <path
          fill="#ebf7f4"
          d="M485.516-623.169h-14.992l25.663-45.131 68.61-40.932h64.981l-144.262 86.063"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M438.848-595.328l12.488-21.963L472.207-654l23.98-14.305-25.663 45.133h14.992l-46.668 27.841m190.93-113.906H564.8l23.092-13.776h64.978l-23.092 13.776"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#a1d3f2"
          d="M832.13-509.551l64.6-113.618 6.208-10.919-6.208 10.92-27.151 47.748-37.453 65.87"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M832.13-509.551H694.192l23.092-13.777h106.835l48.935-86.064h-11.508l41.4-24.7-6.208 10.92-64.6 113.618"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M824.119-523.328H717.284l144.262-86.064h11.508l-48.935 86.064M534.323-623.169h-21.531l122.1-72.841-11.052 19.439-89.513 53.4M437.771-565.568l11.055-19.44 40.874-24.384h21.532l-73.46 43.824M647.814-690.876l10.439-18.359h20.332l-30.769 18.357"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M413.791-551.261l7.328-12.887 3.727-6.555 23.979-14.305-11.053 19.44-23.98 14.307m97.44-58.13H489.7l23.093-13.777h21.531l-23.092 13.777m112.606-67.18l11.052-19.439 45.259-27h21.531l-23.093 13.776h-20.334l-10.439 18.359-23.979 14.3"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M453.276-523.328H439.6l144.262-86.064h1.773l-6.109 10.743-126.253 75.32M620.632-623.169h-11.314l1.211-2.131 140.692-83.934h13.673l-144.262 86.065"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M430.184-509.551H416.51l23.09-13.777h13.673l-23.092 13.777m149.345-89.1l6.109-10.743h-1.773l26.667-15.906-1.211 2.131h11.313l-41.1 24.521m185.363-110.586h-13.673l23.092-13.776h13.675l-23.093 13.776"
          transform="translate(-212.522 841.635)"
        ></path>
        <path fill="#ebf7f4" d="M536.7-523.328h-4.839l7.324-4.369-2.485 4.369" transform="translate(-212.522 841.635)"></path>
        <path
          fill="#f9ffff"
          d="M530.3-509.551h-21.532l23.093-13.777h4.839l2.485-4.369L563.165-542l-10.619 18.676h.845L530.3-509.551"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M720.748-623.169h-21.532l61.352-36.6-11.053 19.439-28.767 17.163M773.494-654.637l11.053-19.44 58.93-35.157h21.533l-91.516 54.6"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M697.654-609.392h-21.53l23.092-13.777h21.532l-23.093 13.777m51.86-30.94l11.053-19.439 23.98-14.307-11.053 19.44-23.979 14.305m115.494-68.9h-21.532l23.093-13.776H888.1l-23.09 13.775"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M553.392-523.328h-.845L563.165-542l112.958-67.388h21.531l-144.262 86.06"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#00b7b2"
          d="M690.389-481.908h-65.725v7.485c22.217 7.58 44.12 16.057 65.725 25.239v-32.724"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#67e5d3"
          d="M624.664-474.423v62.873c0 8.86 14.712 16.045 32.863 16.045s32.863-7.185 32.863-16.045v-37.635c-21.605-9.181-43.508-17.659-65.725-25.239"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#00d4bb"
          d="M1160.91-379.882c-4.364-7.841-12.665-12.6-20.879-16.213a149.967 149.967 0 00-30.955-9.652c-28.972 6.98-49.631 23.828-49.631 35.395v5.7a6.3 6.3 0 006.295 6.3h97.693c1.461-7.277 1.064-15.069-2.524-21.521"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#00b7b2"
          d="M1135.863-408.992a114.382 114.382 0 00-26.785 3.245 149.968 149.968 0 0130.955 9.652c8.213 3.617 16.515 8.372 20.879 16.213 3.588 6.452 3.985 14.244 2.524 21.521h42.548a6.3 6.3 0 006.3-6.3v-5.7c0-14.9-34.212-38.64-76.416-38.64"
          transform="translate(-212.522 841.635)"
        ></path>
        <path fill="#0088db" d="M1382.317-652.588h-368.941L906.386-464.43h368.935l107-188.158" transform="translate(-212.522 841.635)"></path>
        <path
          fill="#014ec0"
          d="M1382.317-652.586l-107 188.157H906.386l5.163 10.2h368.934l106.994-188.161-5.16-10.2"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#c8e8e2"
          d="M1086.157-563.853l18.987-33.392 18.987-33.393h-95.408l-37.973 66.785h95.408M984.671-553.161l-37.977 66.787h95.406l18.988-33.393 18.988-33.393h-95.405M1136.427-630.638l-37.973 66.785h101.22l18.987-33.392 18.987-33.393h-101.22M1249.943-630.638l-37.974 66.785h97.222l37.979-66.785h-97.227"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#eff"
          d="M1186.9-519.768l18.988-33.393h97.222l-37.973 66.787h-97.224l18.987-33.393m25.067-44.085l37.975-66.785h97.226l-37.979 66.785h-97.222m6.691-33.392l-18.987 33.392h-101.22l37.973-66.785h101.22l-18.987 33.393m-94.529-33.393l-18.987 33.393-18.987 33.392H990.75l37.973-66.785h95.408M1042.1-486.375h-95.406l37.977-66.787h95.406l-18.988 33.393-18.989 33.394m113.517 0H1054.4l37.974-66.787h101.221l-37.976 66.787m209.925-154.954h-343.039l-46.647 82.038-47.54 83.605h343.036l50.132-88.168 44.056-77.476"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#c8e8e2"
          d="M1303.113-553.161h-97.223l-18.99 33.393-18.987 33.393h97.224l37.973-66.787M1092.373-553.161l-37.973 66.786h101.22l37.976-66.787h-101.223"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M1002.383-563.853H990.75l19.913-35.023 53.243-31.763h50.424l-111.946 66.785"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M966.168-542.247l9.691-17.044 16.2-28.484 18.608-11.1-19.917 35.022h11.633l-36.215 21.607m148.161-88.392h-50.424l17.919-10.691h50.425l-17.92 10.691"
          transform="translate(-212.522 841.635)"
        ></path>
        <path fill="#a1d3f2" d="M1321.487-563.853l4.817-8.472-4.817 8.472" transform="translate(-212.522 841.635)"></path>
        <path
          fill="#f9ffff"
          d="M1271.356-475.685h-107.041l17.92-10.689h82.905l37.973-66.786h-8.931l32.123-19.164-4.817 8.472-21.07 37.052-29.062 51.115"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M1265.14-486.375h-82.905l111.948-66.787h8.931l-37.973 66.787M1040.256-563.853h-16.707l94.748-56.524-8.577 15.084-69.463 41.439M965.334-519.155l8.577-15.084 31.719-18.923h16.708l-57.005 34.008M1128.327-616.393l8.1-14.245h15.773l-23.876 14.245"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M946.724-508.052l8.576-15.084 18.609-11.1-8.576 15.084-18.608 11.1m75.613-45.109h-16.708l17.92-10.692h16.707l-17.919 10.692m87.383-52.132l8.576-15.083 35.119-20.952h16.709l-17.925 10.69h-15.777l-8.1 14.245-18.607 11.1"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M977.364-486.375h-10.61l111.946-66.786h1.376l-4.74 8.337-97.973 58.449M1107.233-563.853h-8.78l.939-1.651 109.177-65.133h10.611l-111.948 66.785"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M959.446-475.685h-10.612l17.92-10.689h10.611l-17.919 10.689m115.892-69.139l4.74-8.337h-1.378l20.691-12.343-.939 1.651h8.78l-31.9 19.029m143.844-85.814h-10.606l17.919-10.691h10.611l-17.92 10.691"
          transform="translate(-212.522 841.635)"
        ></path>
        <path fill="#ebf7f4" d="M1042.1-486.375h-3.755l5.683-3.392-1.929 3.393" transform="translate(-212.522 841.635)"></path>
        <path
          fill="#f9ffff"
          d="M1037.135-475.685h-16.709l17.921-10.689h3.755l1.929-3.393 18.607-11.1-8.239 14.493h.656l-17.92 10.689"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M1184.921-563.853h-16.707l47.608-28.4-8.577 15.084-22.324 13.317M1225.854-588.272l8.577-15.084 45.729-27.283h16.708l-71.015 42.367"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#f9ffff"
          d="M1167-553.161h-16.708l17.92-10.692h16.707L1167-553.161m40.243-24.009l8.576-15.084 18.608-11.1-8.576 15.084-18.608 11.1m89.624-53.468h-16.708l17.921-10.691h16.708l-17.921 10.691"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#ebf7f4"
          d="M1055.055-486.375h-.656l8.241-14.495 87.654-52.292H1167l-111.948 66.787"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#00b7b2"
          d="M1161.364-454.234h-51v5.808c17.241 5.884 34.239 12.46 51 19.585v-25.393"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="#67e5d3"
          d="M1110.36-448.426v48.789c0 6.877 11.417 12.452 25.5 12.452s25.5-5.575 25.5-12.452v-29.2c-16.765-7.125-33.763-13.7-51-19.585"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="url(#e)"
          d="M1340.917-358.36h-215.349c-7.233-5.889-14.352-13.856-18.015-22.433s-3.617-19.22 2.1-26.592c4.2-5.417 10.764-8.4 15.853-12.989 7.169-6.464 11.031-15.707 16.311-23.787s13.365-15.679 23.012-15.379c7.639.236 14.372 5.4 21.977 6.145 11.3 1.1 20.895-7.477 31.171-12.3 17.335-8.139 37.78-5.589 56.2-.345 28.844 8.211 57.188 23.884 72.212 49.841 10.453 18.072 6.933 41.031-5.467 57.839"
          transform="translate(-212.522 841.635)"
        ></path>
        <path
          fill="url(#f)"
          d="M1340.917-358.36h-215.349c-7.233-5.889-14.352-13.856-18.015-22.433s-3.617-19.22 2.1-26.592c4.2-5.417 10.764-8.4 15.853-12.989 7.169-6.464 11.031-15.707 16.311-23.787s13.365-15.679 23.012-15.379c7.639.236 14.372 5.4 21.977 6.145 11.3 1.1 20.895-7.477 31.171-12.3 17.335-8.139 37.78-5.589 56.2-.345 28.844 8.211 57.188 23.884 72.212 49.841 10.453 18.072 6.933 41.031-5.467 57.839z"
          transform="translate(-212.522 841.635)"
        ></path>
      </svg>
    </StyledWrapper>
  );
};

export default PanelsImage;
